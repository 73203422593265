import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/atoms/Heading';
import FiscalDocumentsSection from './organisms/FiscalDocumentsSection';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import { fetchSATLinkedById } from '../../services/satLinking.service';
import { linkedAccount } from '../../redux/slices/sat.slice';
import Text from '../../components/atoms/Text';
import Tabs from '../../components/molecules/Tabs';
import SATLinkingForm from '../../components/molecules/SATLinkingForm';
import { useNavigate } from 'react-router-dom';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import ERROR from '../../shared/constants/errors';
import { showAlert } from '../../redux/slices/alert.slice';
import { CircularProgress } from '@mui/material';
import Container from '../../components/atoms/Container';

const items = [
	{
		label: 'Contraseña',
		slug: 'password',
	},
	{
		label: 'e.Firma',
		slug: 'efirma',
	},
];

const FiscalDocuments = () => {
	const [activeTab, setActiveTab] = useState(items[0]?.slug);
	const [isLoading, setIsLoading] = useState({
		linkedAccount: false,
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const userLinked = !isLoading.linkedAccount && status?.status && userProfile;
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const fetchSatLinkedAcount = async () => {
		setIsLoading({ ...isLoading, linkedAccount: true });
		try {
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
		} catch (error: Error | any) {
			if (error?.response?.status === 404) return;
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading({ ...isLoading, linkedAccount: false });
		}
	};

	const successSATLinkHandler = async () => {
		fetchSatLinkedAcount();
	};

	useEffect(() => {
		fetchSatLinkedAcount();
	}, []);

	if (isLoading.linkedAccount) {
		return (
			<div className='flex items-center justify-center mt-10'>
				<CircularProgress size={25} />
			</div>
		);
	}

	if (!userLinked) {
		return (
			<Container sx='w-full max-w-[600px] mx-auto sm:mt-10'>
				<div className='rounded-lg border  border-h-primary'>
					<Text
						size='m'
						sx='text-center mt-6 mb-8 mx-4 lg:text-xl'
						weight='medium'
					>
						Vincula tu RFC para acceder a tus documentos fiscales
					</Text>
					<Tabs
						items={items}
						onClickTab={(val) => setActiveTab(val)}
					/>
					<SATLinkingForm
						onCallbackSuccess={successSATLinkHandler}
						onCallbackError={() => {
							navigate(location.pathname, {});
						}}
						showEFirmaForm={activeTab !== 'password'}
						sx='p-6'
						buttonLabel='Vincular mi RFC'
					/>
				</div>
			</Container>
		);
	}

	return (
		<section className='p-3 lg:py-6 lg:px-12 flex flex-col gap-4 bg-h-neutral-94 min-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-46px)]'>
			<Heading
				size='s'
				weight='bold'
				responsive
			>
				Documentos fiscales
			</Heading>
			<FiscalDocumentsSection />
		</section>
	);
};

export default FiscalDocuments;
