import { useState, useCallback } from 'react';
import {
	getOrders,
	postAddProductsBulk,
	postOrders,
} from '../services/order.service';
import { ANALYTICS_EVENTS } from '../shared/constants/events';
import { Item } from '../@types/Ecommerce';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ROUTES from '../shared/constants/routes';
import { ALERT_TYPE } from '../components/molecules/Alert';
import { showAlert } from '../redux/slices/alert.slice';
import { updateAmountOfItems } from '../redux/slices/cart.slice';
import ERROR from '../shared/constants/errors';
import { APP_CONFIGURATIONS } from '../shared/constants/configurations';
import {
	getElasticyPrice,
	getPriceFromProductVersionId,
} from '../shared/utils/products.utils';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../shared/utils/growthbook.util';
import { PlatformOS } from '../redux/slices/profile.slice';

const useAddToCart = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState({ products: false });

	const [searchParams] = useSearchParams();
	const { dataLayer } = window as any;

	const { products } = useSelector((state: RootState) => state.productList);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const platformOS = useSelector(
		(state: RootState) => state.profile.platformOS,
	);

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const getProductBySlug = (slug: string) => {
		const allProducts = products?.flatMap((item) => item?.products);
		return allProducts?.find((item) => item?.slug === slug);
	};

	const getOrderId = useCallback(async () => {
		try {
			const response = await getOrders('false', undefined, tokenSession);
			if (response.length === 0) {
				const postNewOrder = await postOrder();
				dispatch(updateAmountOfItems(1));

				return postNewOrder?.id;
			}

			dispatch(updateAmountOfItems(response?.[0]?.items?.length || 0));
			return response?.[0]?.id;
		} catch (error) {}
	}, []);

	const postOrder = async () => {
		try {
			const channelSource = searchParams.get('channel_source');
			let sourceChannel = 'web';
			if (
				channelSource === 'app' ||
				platformOS == PlatformOS.IOS ||
				PlatformOS.ANDROID
			) {
				sourceChannel = platformOS ?? 'app';
			}
			const body = {
				status: 'open',
				source_channel: sourceChannel,
			};
			const response = await postOrders(body, tokenSession);

			if (response) {
				return response;
			}
		} catch (error) {}
	};

	const postAddProduct = async (body: object, orderId: number) => {
		try {
			const response = await postAddProductsBulk(orderId, body, tokenSession);

			dataLayer.push({ ecommerce: null });
			dataLayer.push({
				event: ANALYTICS_EVENTS.ADD_TO_CART,
				rfc: '',
				ecommerce: {
					items: response?.map((item: Item) => ({
						price: item?.base_price / 100,
						item_id: item?.product_version?.product_id,
						item_name: item?.product_version?.product?.name,
						category: item?.product_version?.product?.regime,
						item_category1: item?.product_version?.product?.category,
						item_category2: item?.purchase_type,
					})),
				},
			});
		} catch (error: any | Error) {
			throw error;
		}
	};

	const onErrorAddProducts = (error: Error | any) => {
		if (error?.response?.data?.message?.includes(ERROR.already_on_cart)) {
			navigate(ROUTES.CHECKOUT);
			return;
		}

		if (error?.response?.data?.message?.includes('Ya cuentas')) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.already_on_cart,
				description: ERROR.error_to_add_product,
			};
			dispatch(showAlert(alert));
			return;
		}

		if (error?.response?.data?.message) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.error_to_add_product,
				description: error?.response?.data?.message,
			};
			dispatch(showAlert(alert));
			return;
		}

		const alert = {
			type: ALERT_TYPE.ERROR,
			title: ERROR.error_to_add_product,
			description: ERROR.failed_to_fetch,
		};
		dispatch(showAlert(alert));
	};

	const handleAddProductBySlugBulk = async (
		payload: {
			slug: string;
			additional_information: {
				year: number;
				month?: number;
				period?: string;
				type?: string;
			};
		}[],
		navigateToCart: boolean = true,
	) => {
		if (isLoading.products) return;
		setIsLoading({ ...isLoading, products: true });

		const productsToAdd = payload.map((item) => {
			const productId = getProductBySlug(item?.slug)
				?.default_product_version_id as number;

			const _price =
				getElasticyPrice(
					getPriceFromProductVersionId(products, productId),
					elasticityPrice.group_experiment,
				) * 100;
			return {
				product_version_id: productId,
				additional_information: item?.additional_information,
				custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
					? {
							group_experiment: elasticityPrice.group_experiment,
							total_paid: _price.toString(),
						}
					: undefined,
			};
		});

		try {
			const orderId = await getOrderId();

			const response = await postAddProduct(productsToAdd, orderId!);

			if (navigateToCart) {
				navigate(ROUTES.CHECKOUT);
			}

			return response;
		} catch (error: any | Error) {
			onErrorAddProducts(error);
		} finally {
			setIsLoading({ ...isLoading, products: false });
		}
	};

	const handleAddProductBySlug = async (
		product_slug: string,
		additional_information: {
			year: number;
			month?: number;
			period?: string;
			type?: string;
			regime?: string[];
		},
		navigateToCart: boolean = true,
	) => {
		if (isLoading.products) return;
		setIsLoading({ ...isLoading, products: true });

		const productId = getProductBySlug(product_slug)
			?.default_product_version_id as number;

		try {
			const orderId = await getOrderId();

			const _price =
				getElasticyPrice(
					getPriceFromProductVersionId(products, productId),
					elasticityPrice.group_experiment,
				) * 100;
			const response = await postAddProductsBulk(orderId!, [
				{
					product_version_id: productId,
					additional_information,
					custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
						? {
								group_experiment: elasticityPrice.group_experiment,
								total_paid: _price.toString(),
							}
						: undefined,
				},
				tokenSession,
			]);

			if (navigateToCart) {
				navigate(ROUTES.CHECKOUT);
			}

			return response;
		} catch (error: any | Error) {
			onErrorAddProducts(error);
		} finally {
			setIsLoading({ ...isLoading, products: false });
		}
	};

	const handleAddCustomProduct = async (
		payload: object[],
		navigateToCart: boolean = true,
	) => {
		if (isLoading.products) return;
		setIsLoading({ ...isLoading, products: true });

		try {
			const orderId = await getOrderId();
			const response = await postAddProductsBulk(
				orderId!,
				payload,
				tokenSession,
			);

			if (navigateToCart) {
				navigate(ROUTES.CHECKOUT);
			}
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: response?.[0]?.product_version?.product?.name || '¡Listo!',
				description: 'Producto agregado al carrito exitosamente',
			};
			dispatch(showAlert(alert));

			return response;
		} catch (error: any | Error) {
			onErrorAddProducts(error);
		} finally {
			setIsLoading({ ...isLoading, products: false });
		}
	};

	return {
		handleAddProductBySlugBulk,
		handleAddProductBySlug,
		handleAddCustomProduct,
		isLoading,
	};
};

export default useAddToCart;
