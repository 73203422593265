import React, { memo, useCallback, useEffect, useState } from 'react';
import Input from '../../atoms/Input';
import {
	ArrowCounterClockwise,
	CircleNotch,
	LockKeyOpen,
	Password,
	Question,
	ShieldCheck,
	UserPlus,
} from '@phosphor-icons/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../atoms/Text';
import Modal from '../../molecules/Modal/Modal';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import LinkButton from '../../atoms/LinkButton';
import LITERALS from '../../../shared/constants/literals';
import {
	fetchUserSATPolling,
	linkSATAccountHeru,
	postSATLinkFlow,
} from '../../../services/satLinking.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaSATLinking } from '../../molecules/SATLinkingForm';
import { getUserProfile } from '../../../services/profile.service';
import { Profile } from '../../../@types/Profile.type';
import toast, { Toaster } from 'react-hot-toast';
import {
	POLLING_STATUS,
	SATLinkingAccount,
	SATPolling,
	TUserLink,
} from '../../../@types/SATLinking';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import TypingText from '../../molecules/TypingText';
import ProductCard from '../ProductCard';
import { ListProducts, Products } from '../../../@types/Products.type';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import clevertap from 'clevertap-web-sdk';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import { isSessionStorageEnabled } from '../../../shared/utils/general.util';
import { setOverwriteUtm, updateUTMs } from '../../../redux/slices/cart.slice';
import { RootState } from '../../../redux/store';
import { useSATLinkFlow } from '../../../shared/constants/links';

type SATLinkForm = {
	rfc: string;
	password: string;
};

const faqs = (onClick: (action: string) => void) => [
	{
		question: '¿Cuál es la contraseña del SAT?',
		answer: (
			<Text>
				Es la contraseña que registraste en la página de SAT ID o en las
				oficinas del SAT, es de 8 caracteres y contiene al menos un número.
			</Text>
		),
	},
	{
		question: '¿Por qué me piden mi contraseña del SAT y mi RFC?',
		answer: (
			<Text>
				Es necesario para acceder a tu perfil del SAT y que tengas acceso a tu
				constancia, presentar tus declaraciones, facturación y más.
			</Text>
		),
	},
	{
		question: '¿Puedo usar Heru sin estar dado de alta en el SAT?',
		answer: (
			<Text sx='!inline-block'>
				No, pero en caso de que no te sepas tu contraseña o no estes dado de
				alta te podemos dar atención personalizada, da{' '}
				<button
					className='text-h-primary'
					onClick={() => onClick('recommendASAT')}
				>
					{' '}
					click aquí para ver los detalles.
				</button>
			</Text>
		),
	},
];

const help_options = [
	{
		icon: (
			<UserPlus
				size={20}
				color='var(--h-primary)'
				weight='fill'
			/>
		),
		title: 'No estoy dado de alta en el SAT',
		description: 'Tenemos un plan para ti con atención personalizada',
		action: 'recommendASAT',
	},
	{
		icon: (
			<Password
				size={20}
				color='var(--h-primary)'
				weight='fill'
			/>
		),
		title: 'Genera tu contraseña con SAT ID',
		description:
			'Puedes generar tu contraseña fácilmente desde la página oficial',
		action: 'satId',
	},
	{
		icon: (
			<ArrowCounterClockwise
				size={20}
				color='var(--h-primary)'
				weight='bold'
			/>
		),
		title: 'Recupera tu contraseña',
		description: 'Puedes recuperar tu contraseña desde la página de SAT ID',
		action: 'satIdRecover',
	},
];

type Props = {
	userIntentDescription: string;
	recommendation: string;
	products: ListProducts[];
	handleAddProduct: (
		product_id: number,
		regime?: string,
		period?: number,
	) => void;
	sendClevertapEvent: (event_type: string) => void;
};

const LinkingForm: React.FC<Props> = ({
	userIntentDescription,
	recommendation,
	products,
	handleAddProduct,
	sendClevertapEvent,
}) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<SATLinkForm>({
		resolver: yupResolver(schemaSATLinking),
		defaultValues: {
			rfc: '',
			password: '',
		},
	});

	const [isLoading, setIsLoading] = useState({
		linking: false,
		userProfile: false,
		satId: false,
	});
	const [numbersOfIntents, setNumbersOfIntents] = useState(0);
	const [userProfile, setUserProfile] = useState<null | Profile>(null);
	const [showModal, setShowModal] = useState({
		faqs: false,
		help: false,
		recommendASAT: false,
		satId: false,
	});
	const [userRFC, setUserRFC] = useState('');
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const { dataLayer } = window as any;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const saveUserProfile = useCallback(async () => {
		try {
			const userInfo = await getUserProfile(tokenSession);
			setUserProfile(userInfo);
		} catch (error) {}
	}, []);

	const errorLinkHandler = (error: any | Error) => {
		setNumbersOfIntents(numbersOfIntents + 1);
		const errorMessage = error?.response?.data?.msg;
		if (
			errorMessage ===
			'Ya tienes un registro activo de vinculación, por favor contacta a soporte.'
		) {
			if (recommendation === 'csf') {
				navigate(ROUTES.ROOT);
				return;
			}
			if (recommendation === 'invoicing') {
				navigate(ROUTES.INVOICING);
				return;
			}
			navigate(ROUTES.CHECKOUT);
			return;
		}
		if (errorMessage) {
			toast.error(errorMessage, {
				position: 'top-right',
			});
			return;
		}
		if (typeof error === 'string') {
			toast.error(error, {
				position: 'top-right',
			});
			return;
		}
		toast.error(
			'Lo sentimos, no pudimos vincular tu cuenta, intenta más tarde ',
			{
				position: 'top-right',
			},
		);
	};

	const successLinkHandler = (rfc: string) => {
		// const { utm_campaign, utm_medium, utm_source } =
		// 	PLANS_UTM_VALUES.LINK_SUCCESS;
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			// const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			// const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			// const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			// if (overwriteUtm === 'true') {
			// 	sessionStorage.setItem(campaignKey, utm_campaign);
			// 	sessionStorage.setItem(mediumKey, utm_medium);
			// 	sessionStorage.setItem(sourceKey, utm_source);
			// } else {
			// 	if (!sessionStorage.getItem(campaignKey)) {
			// 		sessionStorage.setItem(campaignKey, utm_campaign);
			// 	}
			// 	if (!sessionStorage.getItem(mediumKey)) {
			// 		sessionStorage.setItem(mediumKey, utm_medium);
			// 	}
			// 	if (!sessionStorage.getItem(sourceKey)) {
			// 		sessionStorage.setItem(sourceKey, utm_source);
			// 	}
			// }
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				// dispatch(updateUTMs(PLANS_UTM_VALUES.LINK_SUCCESS));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);
				// const updatedUTMs = {
				// 	...currentUTMs,
				// 	utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
				// 	utm_medium: currentUTMs?.utm_medium || utm_medium,
				// 	utm_source: currentUTMs?.utm_source || utm_source,
				// };
				if (!currentUTMs) return;
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign,
					utm_medium: currentUTMs?.utm_medium,
					utm_source: currentUTMs?.utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}
		clevertap.profile.push({
			Site: {
				rfc,
			},
		});
		if (recommendation === 'csf') {
			navigate(ROUTES.ROOT);
			return;
		}
		if (recommendation === 'invoicing') {
			navigate(ROUTES.INVOICING);
			return;
		}
		navigate(ROUTES.CHECKOUT);
	};

	//? POLLING SAT LINK FLOW
	const pullingLinkHandler = async () => {
		try {
			const response: SATPolling = await fetchUserSATPolling(
				userProfile?.id!,
				tokenSession,
			);

			switch (response.code) {
				case POLLING_STATUS.PENDING:
					setTimeout(() => {
						pullingLinkHandler();
					}, 2000);
					break;

				case POLLING_STATUS.DONE:
					successLinkHandler(userRFC);
					break;

				case POLLING_STATUS.FAIL:
					errorLinkHandler(response.errorDetail);
					break;

				default:
					break;
			}
		} catch (error) {}
	};

	//? POST SAT LINK FLOW
	const linkSatHandler = async (userData: TUserLink) => {
		try {
			await postSATLinkFlow(userData, tokenSession);
			setTimeout(() => {
				pullingLinkHandler();
			}, 15000);
		} catch (error) {
			errorLinkHandler(error);
		}
	};

	const onSubmit: SubmitHandler<SATLinkForm> = async (data) => {
		if (!userProfile?.id) return;
		if (numbersOfIntents === 4) {
			setNumbersOfIntents(0);
		}
		try {
			setIsLoading((prev) => ({ ...prev, linking: true }));
			const userData = {
				userId: userProfile?.id,
				rfc: data.rfc,
				password: data.password,
				source: LITERALS.PLATFORM,
			};

			sendClevertapEvent(EVENT_TYPES.ONBOARDING_LINK_CREDENTIALS);
			dataLayer.push({
				event: ANALYTICS_EVENTS.ADD_RFC,
				user_id: userProfile?.id,
				phone: userProfile?.cellphone,
				rfc: data.rfc,
			});
			setUserRFC(data.rfc);

			if (useSATLinkFlow) {
				linkSatHandler(userData);
				return;
			}
			const resp = (await linkSATAccountHeru(
				userData,
				tokenSession,
			)) as SATLinkingAccount;
			const respMessage = resp?.msg;
			if (respMessage) {
				toast.error(respMessage, {
					position: 'top-right',
				});
				return;
			}
			successLinkHandler(data.rfc);
		} catch (error: any | Error) {
			errorLinkHandler(error);
		} finally {
			setIsLoading((prev) => ({ ...prev, linking: false }));
		}
	};

	useEffect(() => {
		saveUserProfile();
	}, []);

	useEffect(() => {
		if (numbersOfIntents === 3) {
			setShowModal({ ...showModal, help: true });
		}
	}, [numbersOfIntents]);

	const handleSwitchToRecommendASAT = (action: string) => {
		switch (action) {
			case 'satId':
				setShowModal({ ...showModal, satId: true });
				sendClevertapEvent(
					EVENT_TYPES.ONBOARDING_HELP_LINK_CREDENTIALS_CREATE_PASSWORD,
				);
				break;
			case 'satIdRecover':
				setShowModal({ ...showModal, satId: true });
				sendClevertapEvent(
					EVENT_TYPES.ONBOARDING_HELP_LINK_CREDENTIALS_RECOVER_PASSWORD,
				);
				break;
			case 'recommendASAT':
				sendClevertapEvent(EVENT_TYPES.ONBOARDING_HELP_LINK_CREDENTIALS_ASAT);
				setShowModal({ ...showModal, recommendASAT: true });
				break;
			default:
				break;
		}
	};

	const ASATBasic = products?.[3]?.products?.find(
		(item) => item.default_product_version_id === Products.ASATBasic,
	);

	const handleAddASATBasic = async () => {
		handleAddProduct(ASATBasic?.default_product_version_id as number);
		// const { utm_campaign, utm_medium, utm_source } =
		// 	PLANS_UTM_VALUES.ASAT_RECOMMENDATION;
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			// const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			// const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			// const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			// if (overwriteUtm === 'true') {
			// 	sessionStorage.setItem(campaignKey, utm_campaign);
			// 	sessionStorage.setItem(mediumKey, utm_medium);
			// 	sessionStorage.setItem(sourceKey, utm_source);
			// } else {
			// 	if (!sessionStorage.getItem(campaignKey)) {
			// 		sessionStorage.setItem(campaignKey, utm_campaign);
			// 	}
			// 	if (!sessionStorage.getItem(mediumKey)) {
			// 		sessionStorage.setItem(mediumKey, utm_medium);
			// 	}
			// 	if (!sessionStorage.getItem(sourceKey)) {
			// 		sessionStorage.setItem(sourceKey, utm_source);
			// 	}
			// }
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				// dispatch(updateUTMs(PLANS_UTM_VALUES.ASAT_RECOMMENDATION));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);

				// const updatedUTMs = {
				// 	...currentUTMs,
				// 	utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
				// 	utm_medium: currentUTMs?.utm_medium || utm_medium,
				// 	utm_source: currentUTMs?.utm_source || utm_source,
				// };
				if (!currentUTMs) return;
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign,
					utm_medium: currentUTMs?.utm_medium,
					utm_source: currentUTMs?.utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}
		navigate(ROUTES.CHECKOUT);
	};

	const handleShowModal = () => {
		sendClevertapEvent(EVENT_TYPES.ONBOARDING_HELP_LINK_CREDENTIALS);
		setShowModal({ ...showModal, help: true });
	};

	return (
		<div className='max-w-sm mx-auto pt-5 z-5 z-10 relative'>
			<Toaster />
			<Modal
				open={showModal.faqs}
				onClose={() => setShowModal({ ...showModal, faqs: false })}
				sx='w-[94%] !max-w-md'
			>
				<div className='my-4'>
					{faqs(handleSwitchToRecommendASAT).map(
						({ question, answer }, index) => (
							<div key={`faq-index-${index}`}>
								<Text
									color='dark'
									weight='bold'
									sx='mt-2 mr-5'
								>
									{question}
								</Text>
								<Text sx='mt-2'>{answer}</Text>
								{index !== faqs(handleSwitchToRecommendASAT).length - 1 && (
									<hr className='my-4' />
								)}
							</div>
						),
					)}
				</div>
			</Modal>
			<Modal
				open={showModal.recommendASAT}
				onClose={() => setShowModal({ ...showModal, recommendASAT: false })}
				sx='w-[94%] !max-w-md'
			>
				<div className='flex flex-col pb-6'>
					<TypingText
						text='Obtén atención personalizada con:'
						duration={150}
						sx='mt-2 mb-4'
					/>
					<ProductCard
						productName={'Servicio de Trámites con el SAT Basic'}
						description={'Servicio de Trámites con el SAT Basic'}
						tagLabel={'Más vendido'}
						price={ASATBasic?.price}
						buttonLabel={'Agregar al carrito y continuar'}
						benefits={[
							'Actualizamos tu domicilio fiscal.',
							'Te damos de alta en el régimen que necesites.',
							'Te proporcionamos todos tus documentos fiscales importantes.',
						]}
						handleClick={handleAddASATBasic}
					/>
				</div>
			</Modal>
			<Modal
				open={showModal.satId}
				onClose={() => setShowModal({ ...showModal, satId: false })}
			>
				<div className='!w-[calc(100vw-60px)] max-w-[500px] h-[calc(100vh-100px)] pt-6 relative'>
					{isLoading.satId && (
						<Text
							sx='absolute top-2 left-2 bg-white border border-h-gray-30 w-fit px-2 py-1 rounded-xl'
							weight='medium'
						>
							<CircleNotch
								size={22}
								color='var(--h-primary)'
								weight='bold'
								className='animate-spin '
							/>
							Cargando SATID
						</Text>
					)}
					<Text
						size='s'
						sx='text-center bg-h-gray-10 flex justify-center py-2 mt-[-20px] z-10'
						color='medium'
					>
						Aviso: SATID es una página ajena a Heru.
					</Text>
					<iframe
						title='Embedded Page'
						width='100%'
						height='100%'
						src='https://satid.sat.gob.mx/'
						allowFullScreen
						onLoad={() => setIsLoading((prev) => ({ ...prev, satId: false }))}
					></iframe>
				</div>
			</Modal>
			<Modal
				open={showModal.help}
				onClose={() => setShowModal({ ...showModal, help: false })}
				sx='w-[94%] !max-w-md'
			>
				<div className='my-4 flex flex-col items-center max-w-[340px] mx-auto'>
					<Text
						size='xl'
						weight='bold'
						sx='text-center mt-2'
					>
						Te ayudamos a vincular
					</Text>
					<Text
						color='medium'
						size='s'
						sx='text-center mt-2'
					>
						¿No recuerdas tu contraseña? No te preocupes, tenemos varias
						opciones para ti.
					</Text>
					<div className='mt-6 flex flex-col gap-3'>
						{help_options.map(({ icon, title, description, action }, index) => (
							<button
								key={`help-option-index-${index}`}
								onClick={() => handleSwitchToRecommendASAT(action)}
								className='flex gap-4 sm:gap-5 items-center bg-h-gray-10 hover:bg-h-gray-20/50 transition p-4 rounded-3xl'
							>
								<div className='bg-white h-[48px] min-w-[48px] shadow-lg rounded-full grid place-items-center'>
									{icon}
								</div>
								<div className=''>
									<Text
										size='s'
										weight='bold'
									>
										{title}
									</Text>
									<Text
										color='medium'
										size='s'
										sx='mt-1 text-left'
									>
										{description}
									</Text>
								</div>
							</button>
						))}
					</div>
				</div>
			</Modal>
			<LockKeyOpen
				size={32}
				color='var(--h-primary)'
				className='mx-auto mt-3'
				weight='fill'
			/>
			<Heading
				size='xs'
				color='dark'
				weight='bold'
				sx='text-center mt-2'
			>
				Solo un paso más
			</Heading>
			<TypingText
				text={userIntentDescription}
				duration={100}
				center
			/>
			<form
				className='mt-8 mx-auto'
				onSubmit={handleSubmit(onSubmit)}
			>
				<Input
					control={control}
					name='rfc'
					type='text'
					errorMessage={errors.rfc?.message}
					autofocus
					maxLength={13}
					showValueUpperCase
					label='RFC'
				/>
				<Input
					control={control}
					name='password'
					type='password'
					errorMessage={errors.password?.message}
					maxLength={8}
					sx='mt-3'
					label='Contraseña del SAT'
				/>
			</form>
			<LinkButton
				label='Preguntas frecuentes'
				beforeIcon={
					<Question
						size={18}
						color='var(--h-primary)'
						weight='fill'
					/>
				}
				onClick={() => setShowModal({ ...showModal, faqs: true })}
				sx='mx-auto mt-4'
			/>
			<div className='bg-h-blue-20/40 mt-12 2xl:mt-20 rounded-lg px-4 py-5 flex flex-col gap-2'>
				<Text
					color='primary'
					sx='mx-auto'
					weight='medium'
				>
					Datos encriptados
					<ShieldCheck
						size={18}
						weight='bold'
					/>
				</Text>
				<Text
					color='primary'
					sx='text-center max-w-[250px] mx-auto'
				>
					Tu RFC y contraseña del SAT se encuentran 100% protegidos.
				</Text>
			</div>
			<Button
				label='Iniciar vinculación'
				sx='mt-5'
				onClick={handleSubmit(onSubmit)}
				loading={isLoading.linking}
			/>
			<Button
				label='Necesito ayuda para vincular'
				variant='outline'
				sx='mt-2'
				onClick={handleShowModal}
			/>
		</div>
	);
};

export default memo(LinkingForm);
