import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import { clarity } from 'react-microsoft-clarity';

//? Types
import { SATLinkStatus } from '../../@types/SATLinking';

//? Components
import SATLinking from '../../components/organisms/SATLinking/SATLinking';
import WhatsappChat from '../../components/organisms/WhatsappChat/WhatsappChat';
import UserCredentialsLinked from '../../components/organisms/UserCredentialsLinked';

//? Services
import { fetchSATLinkedById } from '../../services/satLinking.service';
import { getUserSuscriptions } from '../../services/suscription.service';

//? Utils
import growthbookFile from '../../shared/constants/growthbook';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';

//? Redux
import { RootState } from '../../redux/store';
import { linkedAccount, startLoader } from '../../redux/slices/sat.slice';
import { setUserSubscriptions } from '../../redux/slices/profile.slice';
import { getAllServiceStatus } from '../../services/serviceStatus.service';
import { ServiceStatusElement } from '../../@types/ServiceStatus.type';
import { ANNUAL_COUNTDOWN_DECLARATION_BANNER_PACK } from '../../shared/constants/general';
import BannerCount from '../../components/organisms/BannerCount';
import { REGIMES_CODE } from '../../@types/Regime.type';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import MissingProfileInfoModal from '../../components/organisms/MissingProfileInfoModal';
import { isSessionStorageEnabled } from '../../shared/utils/general.util';
import SESSION_STORAGE_KEY from '../../shared/constants/sessionStorageKey';

const HomePage: React.FC = () => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const { status, isLoading } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);

	const [isLoadingServices, setIsLoadingServices] = useState(false);
	const [serviceStatus, setServiceStatus] = useState<ServiceStatusElement[]>(
		[],
	);
	const [showModalMissingInfo, setShowModalMissingInfo] = useState(false);
	const showHubspotChat = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.WEBHOOK_HUBSPOT,
	);

	const fetchSatLinkedAcount = async () => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: EVENT_TYPES.HOME_MAIN_SCREEN_VIEWED,
				id: userProfile?.id!,
				phone: userProfile?.cellphone!,
				link: resource.status === SATLinkStatus.ACTIVE,
			});
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	const fetchServiceStatus = async () => {
		try {
			setIsLoadingServices(true);
			const resource = await getAllServiceStatus(tokenSession);
			if (Array.isArray(resource) && resource.length > 0) {
				setServiceStatus(resource);
			}
		} catch (error) {
		} finally {
			setIsLoadingServices(false);
		}
	};

	const fetchUserSuscriptions = useCallback(async () => {
		try {
			const subs = await getUserSuscriptions(tokenSession);
			dispatch(setUserSubscriptions(subs));
		} catch (error) {}
	}, []);

	const showBanner = () => {
		return false;
		const regimes = taxPayer?.status?.tax_regimes;
		if (taxPayer) {
			if (
				regimes?.find(
					(regime) =>
						regime?.code?.includes(REGIMES_CODE.ACTIVIDAD_EMPRESARIAL) ||
						regime?.code?.includes(REGIMES_CODE.SUELDOS_Y_SALARIOS),
				)
			) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	useEffect(() => {
		fetchUserSuscriptions();
		fetchServiceStatus();
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (status.status === '') {
				fetchSatLinkedAcount();
			}
		}
		return () => {
			isMounted = false;
		};
	}, [status]);

	const loadGrowthbook = async () => {
		await growthbookFile.loadFeatures();
	};

	useEffect(() => {
		if (!!userProfile?.id) {
			loadGrowthbook();
			growthbookFile?.setAttributes({
				user_id: userProfile?.id,
			});
			logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
				name: '/home',
			});
			if (window.clarity) {
				window.clarity('identify', userProfile.id.toString());
				// clarity.identify(userInfo.id.toString(), { userId: userInfo?.id });
			}
			if (!userProfile?.email || !userProfile?.first_name) {
				if (isSessionStorageEnabled()) {
					let show = sessionStorage.getItem(
						SESSION_STORAGE_KEY.SHOW_NO_INFO_MODAL,
					);
					if (!show) {
						setShowModalMissingInfo(true);

						sessionStorage.setItem(
							SESSION_STORAGE_KEY.SHOW_NO_INFO_MODAL,
							'TRUE',
						);

						return;
					}
				} else {
					setShowModalMissingInfo(true);
				}
			} else {
				setShowModalMissingInfo(false);
			}
		}
	}, [userProfile?.id]);

	useEffect(() => {
		console.log('showModalMissingInfo', showModalMissingInfo);
	}, [showModalMissingInfo]);

	return (
		<section className='p-4 md:px-10 md:py-5'>
			{isLoading ? (
				<div className='flex items-center justify-center mt-5'>
					<CircularProgress size={25} />
				</div>
			) : (
				<>
					{!showHubspotChat && <WhatsappChat />}

					{<MissingProfileInfoModal open={showModalMissingInfo} />}
					{showBanner() && (
						<div className='mb-4 md:mb-6'>
							<BannerCount
								initialDate={
									ANNUAL_COUNTDOWN_DECLARATION_BANNER_PACK.initialDate
								}
								endDate={ANNUAL_COUNTDOWN_DECLARATION_BANNER_PACK.endDate}
								initialValue={
									ANNUAL_COUNTDOWN_DECLARATION_BANNER_PACK.initialValue
								}
								label={'Declaraciones Anuales'}
								bgImage='https://heru-static-assets.s3.us-east-2.amazonaws.com/50off.png'
								bgColor={'#EFB929'}
							/>
						</div>
					)}

					{!status.status && (
						<SATLinking
							serviceStatus={serviceStatus}
							isLoadingServices={isLoadingServices}
						/>
					)}
					{status.status && (
						<UserCredentialsLinked
							serviceStatus={serviceStatus}
							isLoadingServices={isLoadingServices}
						/>
					)}
				</>
			)}
		</section>
	);
};

export default React.memo(HomePage);
