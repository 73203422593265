import {
	CredentialFilesHeruType,
	CredentialHeruType,
} from '../@types/SATLinking';
import HERU_API from '../shared/constants/heruApi';
import { post, get, del } from '../shared/utils/FetchSSL.util';

const fetchSATLinkedById = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_FINANCIAL_LINK, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});

		return response;
	} catch (error) {
		throw error;
	}
};

const SATUnlinkedByUser = async (userId: number, tokenSession?: string) => {
	try {
		const response = await del(
			HERU_API.HERU_API_DELETE_FINANCIAL_LINK(userId),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchUserSATExtractionCheck = async (
	rfc: string,
	tokenSession?: string,
) => {
	try {
		const response = await get(
			HERU_API.HERU_API_SAT_USER_EXTRACTION_CHECK.concat(`/${rfc}`),
			{
				token: true,
				isNewGateway: false,
				tokenSession,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const linkSATAccountHeru = async (
	params: CredentialHeruType,
	tokenSession?: string,
) => {
	try {
		const body = {
			user_id: params.userId,
			rfc: params.rfc,
			password: params.password,
			source: params.source,
		};

		const response = await post(
			HERU_API.HERU_API_FISCAL_CREDENTIALS_HERU,
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const linkSATAccountEFirmaHeru = async (
	params: CredentialFilesHeruType,
	tokenSession?: string,
) => {
	try {
		const body = {
			user_id: params.userId,
			cert: params.cert,
			key: params.key,
			password: params.password,
			source: params.source,
		};

		const response = await post(
			HERU_API.HERU_API_FISCAL_CREDENTIALS_EFIRMA_HERU,
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postSATLinkFlow = async (
	params: CredentialHeruType,
	tokenSession?: string,
) => {
	try {
		const body = {
			userId: params.userId,
			rfc: params.rfc,
			password: params.password,
			source: params.source,
		};

		const response = await post(HERU_API.HERU_API_SIGNIN_SAT_LINK_FLOW, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchUserSATPolling = async (userId: number, tokenSession?: string) => {
	try {
		const response = await get(
			HERU_API.HERU_API_SIGNIN_SAT_LINK_POLLING(userId),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchUserRegimesIncomes = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.REGIMES_INCOMES, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	fetchSATLinkedById,
	SATUnlinkedByUser,
	fetchUserSATExtractionCheck,
	linkSATAccountHeru,
	linkSATAccountEFirmaHeru,
	postSATLinkFlow,
	fetchUserSATPolling,
	fetchUserRegimesIncomes,
};
