import { useEffect, useState } from 'react';
import TabSelector from '../../../components/molecules/TabSelector';
import Text from '../../../components/atoms/Text';
import Tag from '../../../components/molecules/Tag';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ResumeProperty from '../../taxesAndInvoices/molecules/ResumeProperty';
import TableFiscalDocuments from './TableFiscalDocuments';
import FiscalDocumentDetail from './FiscalDocumentDetail';
import { getFormatDate, getMonthName } from '../../../shared/utils/dates.util';
import PropertyDropdown from '../../taxesAndInvoices/molecules/PropertyDropdown';
import { useLocation } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import {
	FiscalDocumentInvoiceDetail,
	FiscalDocumentReceiptDetail,
	FiscalDocumentRetentionDetail,
	FiscalDocumentType,
} from '../../../@types/FiscalDocuments.type';
import CALENDAR from '../../../shared/constants/calendar';
import TagIconCalendar from '../../taxesAndInvoices/atoms/TagIconCalendar';
import { DATES } from '../../../shared/utils/dates';
import { fetchTaxPayer } from '../../../services/taxes.service';
import { addTaxPayer } from '../../../redux/slices/taxProfile.slice';
import Container from '../../../components/atoms/Container';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';

const INVOICES_CATEGORIES = [
	{
		label: 'Facturas',
		slug: FiscalDocumentType.INVOICE,
	},
	{
		label: 'Certificados de retención',
		labelMobile: 'Cert. retención',
		slug: FiscalDocumentType.RETENTION,
	},
	{
		label: 'Acuses',
		slug: FiscalDocumentType.RECEIPT,
	},
];

type Props = {};

export type SelectedFiscalDocument = {
	invoice: FiscalDocumentInvoiceDetail | null;
	retention: FiscalDocumentRetentionDetail | null;
	receipt: FiscalDocumentReceiptDetail | null;
};

const FiscalDocumentsSection: React.FC<Props> = ({}) => {
	const location = useLocation();
	const logClevertapEvent = useCleverTapEvent();

	const { year, selectedPeriod, month, fiscalResume, statusByPriotityMonth } =
		useSelector((state: RootState) => state.taxCalendar);

	const [selectedInvoiceCategory, setSelectedInvoiceCategory] = useState<
		| FiscalDocumentType.INVOICE
		| FiscalDocumentType.RECEIPT
		| FiscalDocumentType.RETENTION
	>(FiscalDocumentType.INVOICE);

	const [selectedDocument, setSelectedDocument] =
		useState<SelectedFiscalDocument>({
			invoice: null,
			receipt: null,
			retention: null,
		});

	const [filterByPeriod, setFilterByPeriod] = useState<{
		year: number;
		month: string | null;
		confirmed: boolean;
	}>({
		year: DATES.currentYear,
		month: null,
		confirmed: false,
	});

	const [currentPage, setCurrentPage] = useState(1);

	const handleCategory = (slug: string) => {
		setCurrentPage(1);
		setFilterByPeriod({
			year: DATES.currentYear,
			month: null,
			confirmed: false,
		});
		// @ts-ignore
		setSelectedInvoiceCategory(slug);
		setSelectedDocument({
			invoice: null,
			receipt: null,
			retention: null,
		});

		logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
			event_type: EVENT_TYPES.FISCAL_DOCUMENTS_TAB,
			source: selectedInvoiceCategory,
		});
	};

	const isMobile = useMediaQuery('(max-width: 640px)');

	const isHalfPage =
		(selectedPeriod.month.detailByRegimeComplete ||
			selectedPeriod.year.selectedDeclarationComplete) &&
		location.pathname === ROUTES.TAXES;

	const { detailByRegime } = selectedPeriod.month;

	const userProfile = useSelector((state: RootState) => state.profile.user);
	const dispatch = useDispatch();

	const getTaxPayer = async () => {
		try {
			if (!userProfile?.id) return;
			const resource = await fetchTaxPayer(userProfile?.id);
			dispatch(addTaxPayer(resource));
		} catch (error) {}
	};

	useEffect(() => {
		getTaxPayer();
	}, []);

	const declarationProperties = [
		{
			label: 'Fecha de presentación',
			value: getFormatDate(
				detailByRegime?.declarationPresentedDate || '',
				'DD MMM YYYY',
				false,
				true,
			),
		},
		{
			label: 'Tipo de declaración',
			value:
				detailByRegime?.declarationType === 'NORMAL'
					? 'Normal'
					: 'Complementaria',
		},
		{
			label: 'Tipo de pago',
			value: detailByRegime?.paymentType === 'NORMAL' ? 'Normal' : 'Definitivo',
		},
		{
			label: 'Presentada por',
			value: detailByRegime?.presentedBy === 'Heru' ? 'Heru' : 'Externo',
		},
	];

	const getDetailByType = () => {
		if (selectedPeriod.month.detailByRegimeComplete === 'regime') {
			return (
				<>
					<Text
						size='caption'
						weight='bold'
					>
						Ejercicio financiero
					</Text>
					<div className='mt-2 ml-2 flex flex-col gap-2'>
						{declarationProperties
							?.filter(
								(item) => item?.value !== undefined || item?.value === null,
							)
							?.map((property, index) => (
								<div key={`property-index-${index}`}>
									<ResumeProperty
										label={property.label}
										value={property.value}
									/>
								</div>
							))}
					</div>
				</>
			);
		}

		return (
			<>
				<div className='mt-2 ml-2 flex flex-col gap-2'>
					<PropertyDropdown
						label={'Ingresos'}
						total={fiscalResume?.month?.incomes?.total}
						amounts={fiscalResume?.month?.incomes?.concepts?.map(
							(amount: {
								concept?: string;
								regime?: string;
								amount: number;
							}) => ({
								label: amount?.concept ?? amount?.regime ?? 'Sin concepto',
								amount: amount?.amount,
							}),
						)}
					/>
					{fiscalResume?.month?.expenses !== undefined &&
						fiscalResume?.month?.expenses !== null && (
							<ResumeProperty
								label={'Gastos'}
								amount={fiscalResume?.month?.expenses}
							/>
						)}
					<PropertyDropdown
						label={'Retenciones'}
						total={fiscalResume?.month?.retentions?.total}
						amounts={[
							{
								label: 'IVA',
								amount: fiscalResume?.month?.retentions?.vat,
							},
							{
								label: 'ISR',
								amount: fiscalResume?.month?.retentions?.isr,
							},
						]}
					/>
					{fiscalResume?.month?.deductibles !== undefined &&
						fiscalResume?.month?.deductibles !== null && (
							<ResumeProperty
								label={'Gastos deducibles'}
								amount={fiscalResume?.month?.deductibles}
							/>
						)}
				</div>
			</>
		);
	};

	return (
		<div className='flex flex-col lg:flex-row gap-4'>
			<Container sx='flex-1'>
				{isHalfPage && (
					<div>
						<Text
							size='body-3'
							weight='bold'
						>
							Declaración
						</Text>
						<div className='mt-2 bg-h-neutral-90/10 border border-h-neutral90 p-3 rounded-lg'>
							<div className='flex justify-between items-center'>
								<div className='flex gap-1'>
									<Text
										// @ts-ignore
										size={'body-1' ? 'body-3' : 'body-1'}
										weight='bold'
									>
										{getMonthName(month, true)}
									</Text>
									<Text
										size='caption'
										weight='regular'
										color='light'
									>
										{year}
									</Text>
									{selectedPeriod?.month?.detailByRegimeComplete ===
										'regime' && (
										<Text
											size='caption'
											weight='regular'
											sx='bg-h-neutral-90/50 ml-2 border border-h-neutral-90 p-1 rounded-md w-fit ml-4 sm:ml-2'
										>
											{isMobile ? 'P.Tecnológicas' : 'Plataformas Tecnólogicas'}
										</Text>
									)}
								</div>
								<Tag
									label={
										CALENDAR.taxboardDeclarationStatusLabel[
											statusByPriotityMonth
										]
									}
									type='filled'
									caseVariant={
										CALENDAR.labelColor[statusByPriotityMonth] ?? 'filter'
									}
									iconRight={<TagIconCalendar status={statusByPriotityMonth} />}
								/>
							</div>
							<hr className='mt-2 mb-4' />
							{getDetailByType()}
						</div>
						<div className='flex justify-between items-end'>
							<Text
								size='body-3'
								weight='bold'
								sx='mt-6'
							>
								Documentos fiscales
							</Text>
						</div>
					</div>
				)}
				<div
					className={`flex justify-between items-center ${isHalfPage ? 'mt-4' : 'mt-10 sm:mt-6'}`}
				>
					<div className='max-w-[90vw] overflow-auto -mb-1'>
						<TabSelector
							handleCategory={handleCategory}
							options={
								isHalfPage
									? INVOICES_CATEGORIES.slice(0, 2)
									: INVOICES_CATEGORIES
							}
							currentOption={selectedInvoiceCategory}
						/>
					</div>
				</div>
				<TableFiscalDocuments
					documentType={selectedInvoiceCategory}
					isHalfPage={isHalfPage}
					selectedDocument={selectedDocument}
					setSelectedDocument={setSelectedDocument}
					filterByPeriod={filterByPeriod}
					setFilterByPeriod={setFilterByPeriod}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			</Container>
			<FiscalDocumentDetail
				setSelectedDocument={setSelectedDocument}
				selectedDocument={selectedDocument}
				documentType={selectedInvoiceCategory}
			/>
		</div>
	);
};

export default FiscalDocumentsSection;
